// import Modal from '@/src/components/Modal/Modal';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { IconPlus } from '@/src/modules/icons';
import { NewResourceTypesOptions } from '@/src/modules/resources/components/NewResource/config';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { setNewModalOpen } from '@/src/store/ui';
import { DialogContent, DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { motion, useWillChange } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled(Modal.Overlay)`
  background-color: rgba(${cssVar['color-bg-secondary-rgb']}, 0.1);
  backdrop-filter: blur(13px);
`;

const IconPlusStyled = styled(IconPlus)`
  height: 1rem;
  width: 1rem;
`;

const ResourceTypeIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 27px;
`;

const ResourceTypeTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  padding: 0.25rem 0.375rem;
`;

const ResourceTypeItemButton = styled(motion.button)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  transition: 0.15s all;

  padding: 0.75rem;
  gap: 0.875rem;
  width: 7.5rem;
  flex-shrink: 1;
  height: 6.25rem;

  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: 1rem;

  background: ${cssVar['color-bg-primary']};
  color: ${cssVar['color-text-primary']};
  ${mediaHover} {
    &:hover {
      background: #e7e2fc;
      border: 1px solid rgba(128, 128, 128, 0.3);
      color: ${cssVar['color-app-primary']};
    }
  }
`;

const ContentWrapper = styled(DialogContent).attrs((props) => {
  return {
    onOpenAutoFocus: (e) => e.preventDefault(),
    onCloseAutoFocus: (e) => e.preventDefault(),
    'aria-label': 'modal',
    ...props,
  };
})`
  position: fixed;
  z-index: 125;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;

  min-width: 0;
  min-height: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  transition: all 0.2s ease;
  will-change: transform, opacity;
  height: max-content;
`;

type ContentPositionAlignment = 'bottom-left' | 'top-center';
const defaultPositionOverrideCss: React.CSSProperties = {
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: 'calc(var(--safe-offset-floating-elements, 0px) + 1rem)',
};

const getModalContentPosition = (
  triggerRect: DOMRect | null,
  alignment: ContentPositionAlignment,
): React.CSSProperties => {
  if (!triggerRect) {
    return defaultPositionOverrideCss;
  }

  switch (alignment) {
    case 'top-center': {
      return {
        bottom: window.innerHeight - triggerRect.top + 16,
        left: triggerRect.left + triggerRect.width / 2,
        top: 'auto',
        transform: 'translateX(-50%)',
      };
    }
    default:
      return {
        top: triggerRect.top + triggerRect.height + 16,
        right: window.innerWidth - triggerRect.right,
        transform: 'none',
      };
  }
};

const ItemsContainer = styled(Flex).attrs({})`
  gap: 0.75rem;
  max-width: calc(100dvw - 2rem);
`;

const NewResourceModalContent: React.FC<{
  onSelect?: (value: string) => void;
  alignment: ContentPositionAlignment;
}> = React.memo(function Content({ onSelect, alignment }) {
  const totalItems = NewResourceTypesOptions.length;

  const willChange = useWillChange();

  return (
    <ItemsContainer>
      {NewResourceTypesOptions.map((option, index) => {
        // we want to reverse the transition order sequence on non-mobile
        const delay = alignment !== 'bottom-left' ? 0.03 * index : 0.03 * (totalItems - index - 1);
        return (
          <ResourceTypeItemButton
            initial={{ translateY: '30%' }}
            animate={{ translateY: 0 }}
            transition={{ duration: 0.1, delay }}
            key={option.value}
            style={{ willChange }}
            data-testid={`type-selector-${option.testId}`}
            onClick={() => {
              onSelect?.(option.value);
              setNewModalOpen(true, { type: option.value });
            }}
          >
            <ResourceTypeIconContainer>
              <option.icon />
            </ResourceTypeIconContainer>
            <ResourceTypeTitle>{option.minLabel}</ResourceTypeTitle>
          </ResourceTypeItemButton>
        );
      })}
    </ItemsContainer>
  );
});

export const ModalNewResourceSelector: React.FC<{
  handleClose?: () => void;
  isOpen?: boolean;
  buttonProps?: React.ComponentPropsWithoutRef<typeof Button> & { ['data-testid']?: string };
  withPlusButton?: boolean;
  contentPositionAlignment?: 'bottom-left' | 'top-center';
}> = ({
  handleClose,
  withPlusButton = true,
  isOpen: isOpenProp,
  buttonProps,
  contentPositionAlignment = 'bottom-left',
}) => {
  const openState = useBoolState(false);

  const isOpen = isOpenProp ?? openState.value;
  const onOpenChange = handleClose ?? openState.modalProps.onOpenChange;

  const triggerButtonRef = React.useRef<HTMLButtonElement>(null);

  const [triggerButtonRect, setTriggerButtonRect] = useDomContentRect(triggerButtonRef.current, {
    observeParentResize: true,
  });

  const contentPosition = withPlusButton
    ? getModalContentPosition(triggerButtonRect, contentPositionAlignment)
    : defaultPositionOverrideCss;

  const mirrorButtonStyle: React.CSSProperties = {
    position: 'fixed',
    top: triggerButtonRect?.top,
    left: triggerButtonRect?.left,
    zIndex: 126,
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      {withPlusButton && (
        <Modal.Trigger asChild>
          <Button
            ref={triggerButtonRef}
            shape="square"
            variant="primary-inverse"
            onClick={(e) => {
              openState.handleTrue();
              e.currentTarget.blur();
              setTriggerButtonRect(e.currentTarget.getBoundingClientRect());
            }}
            data-testid="create-new-resource-from-home-button"
            {...buttonProps}
          >
            <IconPlusStyled />
          </Button>
        </Modal.Trigger>
      )}
      <Modal.Portal>
        <ModalOverlay visibleOnMobileViewport />
        {/**
         * this button is just a mask so we don't have to lift it up from behind the overlay
         */}
        {withPlusButton && (
          <Button
            shape="square"
            variant="primary-inverse"
            onClick={handleClose}
            {...buttonProps}
            style={{
              ...mirrorButtonStyle,
              ...buttonProps?.style,
            }}
          >
            <IconPlusStyled />
          </Button>
        )}
        <ContentWrapper style={contentPosition}>
          <VisuallyHidden>
            <DialogTitle>Select resource type</DialogTitle>
            <DialogDescription>Select resource type to create</DialogDescription>
          </VisuallyHidden>
          <NewResourceModalContent
            alignment={contentPositionAlignment}
            onSelect={openState.handleFalse}
          />
        </ContentWrapper>
      </Modal.Portal>
    </Modal>
  );
};
